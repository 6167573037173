var $ = require('jquery');

var TweenLite = require('gsap');
var scrollTo = require('../../node_modules/gsap/src/uncompressed/plugins/ScrollToPlugin.js');

window.app = {
	W: $(window).width(),

	scrollTopPos: 0,

	isNavShow: false,

	init: function() {
		var _this = this;
		this.setupEvents();
		this.sidenavCarousel();
	},

	setupEvents: function() {
		var _this = this;

		$(window).resize(function(event) {
			_this.onResize();
		});

		$('.header-nav-trigger').click(function(event) {
			event.preventDefault();

			if(_this.isNavShow) {
				_this.hideNav();
			} else {
				_this.showNav();
			}
		});

		$('.content article ul.pointer').delegate('li', 'click', function(event) {
			event.preventDefault();
			$(this).toggleClass('active');
		});

		$('.btn-toggle-all').on('click', function(event) {
			event.preventDefault();
	
			if ($(this).html() == 'Rozwiń wszystkie szczegóły') {
				$(this).html('Zwiń wszystkie szczegóły');
				$(this).parent().parent().find('ul').first().find('li').addClass('active');
			} else {
				$(this).html('Rozwiń wszystkie szczegóły')
				$(this).parent().parent().find('ul').first().find('li').removeClass('active');
			}
		});

		$('.popup-overlay').click(function(event) {
			event.preventDefault();
			// _this.scrollTopPos = 0;
			_this.hideNav();
		});
	},

	onResize: function() {
		var _this = this;

		_this.W = $(window).width();

		if (_this.isNavShow == true && _this.W >= 768) {
			_this.hideNav();
		}
	},

	scrollTo: function(params) {

		if (params.pos == undefined) {params.pos = 0};
		if (params.speed == undefined) {params.speed = 0.3};

		TweenLite.to( window, params.speed, {
			scrollTo: {
				y: params.pos
			},
			ease: Cubic.linear, 
			onComplete: function(){
				if (params.after) {
					params.after();
				}
			}
		});
	},

	showNav: function() {
		var _this = this;

		_this.isNavShow = true;
		_this.scrollTopPos = $(window).scrollTop();
		_this.scrollTo({ pos: 0 });
		$('.header-nav-trigger, .popup-overlay, .sidenav nav').addClass('active');
	},

	hideNav: function() {
		var _this = this;

		_this.scrollTo({ pos: _this.scrollTopPos });

		this.isNavShow = false;
		$('.header-nav-trigger, .popup-overlay, .sidenav nav').removeClass('active');

	},

	sidenavCarousel: function() {
		$('.carousel').each(function(index, el) {
			var i = 1,
				$items = $(el).find('li'),
				itemsLenght = $(el).find('li').length;

			var interval = setInterval(function() {
				i++;

				$items.removeClass('active');
				$(el).find('li:nth-child('+i+')').addClass('active');

				if (i == itemsLenght) {
					i = 0;
				}

			}, 3000);
		});
	}
};

window.$ = $;

$(document).ready(function() {
	window.app.init();
});